<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
      class="node-details-form"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input
          v-model="nodeToBind.node_name"
          :placeholder="__('Prompt recorder node')"
        ></el-input>
      </el-form-item>

      <el-form-item
        :label="__('Prompt')"
        prop="prompt_recorder_node.data.prompt_text"
      >
        <input-variable-popper
          :value="promptText"
          is-content-editable
          @input="updatePromptText($event)"
          :is-text-area="false"
          include-prompts
          include-audio-variables
          :include-secure-variables="false"
          :ats="getOptionsInitiators"
          popper-class="prompt-node-popper"
          class="promptEditor"
        />
        <audio-player
          class="audio-player"
          :disabled="!promptText"
          @get-preview="
            generateAudio(
              'promptText',
              'generatingAudioForPrompt',
              'promptAudioFile',
              'promptAtAudioFileCreation'
            )
          "
          :show-reload="promptContentChanged"
          :generating-audio="generatingAudio"
          :file="promptAudioFile"
        />
      </el-form-item>

      <el-form-item :label="__('Recorder type')">
        <el-radio-group v-model="promptRecorderType">
          <el-radio label="script_prompt">{{ __("Script prompt") }}</el-radio>
          <el-radio label="dynamic_task_prompt_reference">{{
            __("Dynamic task prompt reference")
          }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        prop="prompt_recorder_node.data.recording_prompt_id"
        :label="__('Prompt to record over')"
        v-if="promptRecorderType === 'script_prompt'"
      >
        <el-select
          v-model="nodeToBind.prompt_recorder_node.data.recording_prompt_id"
          v-loading="promptsLoading"
          default-first-option
          filterable
          clearable
        >
          <el-option
            v-for="prompt in prompts"
            :key="prompt.prompt_id"
            :value="prompt.prompt_id"
            :label="prompt.prompt_name"
          ></el-option>
        </el-select>
      </el-form-item>

      <template
        v-else-if="promptRecorderType === 'dynamic_task_prompt_reference'"
      >
        <el-form-item
          prop="prompt_recorder_node.data.recording_variable_id"
          :label="__('Variable')"
        >
          <el-select
            v-model="nodeToBind.prompt_recorder_node.data.recording_variable_id"
            default-first-option
            filterable
            clearable
          >
            <el-option
              v-for="prompt in singleValuedVariables"
              :key="prompt.variable_id"
              :value="prompt.variable_id"
              :label="prompt.variable_name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          prop="prompt_recorder_node.data.recording_prompt_suffix"
          :label="__('Prompt suffix')"
        >
          <el-input
            v-model="
              nodeToBind.prompt_recorder_node.data.recording_prompt_suffix
            "
          ></el-input>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import GenerateAudio from "@/views/build/callflow/components/GenerateAudio";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import { mapActions, mapState } from "vuex";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import AudioPlayer from "@/components/AudioPlayer";

const initPromptRecorderNode = {
  node_type: NODE_TYPES.PROMPT_RECORDER.NODE_TYPE,
  prompt_recorder_node: {
    data: {
      prompt_text: "",
      recording_prompt_id: null,
      recording_prompt_suffix: null,
      recording_variable_id: null
    }
  }
};

export default {
  mixins: [BaseNode, GenerateAudio],
  components: {
    InputVariablePopper,
    AudioPlayer
  },
  data() {
    return {
      rules: {
        prompt_recorder_node: {
          data: {
            prompt_text: {
              required: true,
              message: __("Please add a prompt text"),
              trigger: "blur"
            },
            recording_prompt_id: {
              required: true,
              message: __("Please select a prompt"),
              trigger: "blur"
            },

            recording_variable_id: {
              required: true,
              message: __("Please select a variable"),
              trigger: "blur"
            }
          }
        }
      },
      promptRecorderType: "script_prompt",
      promptAudioFile: "",
      generatingAudio: false,
      promptAtAudioFileCreation: ""
    };
  },
  computed: {
    ...mapState("prompts", {
      prompts: state => state.prompts,
      promptsLoading: state => state.loading
    }),

    promptText() {
      return this.nodeToBind.prompt_recorder_node.data.prompt_text;
    },

    getOptionsInitiators() {
      return ["{{", "[["];
    },

    promptContentChanged() {
      return this.promptText !== this.promptAtAudioFileCreation;
    }
  },
  methods: {
    ...mapActions("prompts", {
      getPrompts: "getPrompts"
    }),

    initializePromptRecorderNode() {
      this.nodeToBind = Object.assign(
        {},
        this.nodeToBind,
        _.cloneDeep(initPromptRecorderNode)
      );
    },

    updatePromptText(value) {
      this.$set(
        this.nodeToBind.prompt_recorder_node.data,
        "prompt_text",
        value
      );
    },

    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      return nodeToCleanUp;
    },

    cleanUpNode() {
      // this.$refs.nodeForm.validate((valid, errors) => {
      //   if (valid) {
      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.createOrEditNode();
      // } else {
      //   console.log(errors);
      //   this.toggleNodeSubmit(false);
      // }
      // });
    }
  },
  created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.prompt_recorder_node)
    ) {
      this.initializePromptRecorderNode();
    } else {
      if (this.nodeToBind.prompt_recorder_node.data.recording_variable_id) {
        this.promptRecorderType = "dynamic_task_prompt_reference";
      } else {
        this.promptRecorderType = "script_prompt";
      }
    }
  },

  watch: {
    promptRecorderType: function(val) {
      if (val === "script_prompt") {
        this.$set(
          this.nodeToBind.prompt_recorder_node.data,
          "recorder_type",
          "script_prompt"
        );
        this.$set(
          this.nodeToBind.prompt_recorder_node.data,
          "recording_variable_id",
          null
        );
        this.$set(
          this.nodeToBind.prompt_recorder_node.data,
          "recording_prompt_suffix",
          null
        );
      } else if (val === "dynamic_task_prompt_reference") {
        this.$set(
          this.nodeToBind.prompt_recorder_node.data,
          "recording_prompt_id",
          null
        );
        this.$set(
          this.nodeToBind.prompt_recorder_node.data,
          "recorder_type",
          "dynamic_task_prompt_reference"
        );
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
@import "~@/styles/node_common.scss";

.audio-player {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}
.promptEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;
    &:focus {
      border: 1px solid black;
    }
  }
}
</style>
